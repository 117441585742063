<template>
  <div class="app-container">
    <eHeader :query="query" @query="queryHandle" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
      <el-table-column prop="id" label="主键ID" />
      <el-table-column prop="language" label="语言/language">
        <template slot-scope="scope">
          <span>{{ getLang(scope.row) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="value" label="数据值" />
      <el-table-column prop="label" label="标签名" />
      <el-table-column prop="type" label="类型"/>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','DICT_ALL','DICT_EDIT'])" :data="scope.row" :sup_this="sup_this"/>
          <el-popover
            v-if="checkPermission(['ADMIN','DICT_ALL','DICT_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del } from '@/api/system/dict'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/system/dict/header'
import edit from '@/components/system/dict/edit'
export default {
  name:'dict',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    getLang(row){
      switch (row.language){
        case 'en_US':
          return '英文';
        case 'zh_CN':
          return '中文';
        case 'zh_Hant':
          return '繁体';
      }
    },
    beforeInit() {
      this.url = 'crm/dict'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query;
      if(query.lang){
        this.params.language = query.lang;
      }
      const type = query.type;
      const value = query.value;
      if (type && value) { this.params[type] = value };
      return true
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    },
    queryHandle(){
      this.page = 0;
      this.init();
    },
  }
}
</script>

<style scoped>

</style>
