<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm
        ref="form"
        :sup_this="sup_this"
        :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        language: this.data.language,
        value: this.data.value,
        label: this.data.label,
        type: this.data.type,
        description: this.data.description,
        sortNo: this.data.sortNo,
        delFlag: this.data.delFlag.toString(),
        remarks: this.data.remarks,
        createTime: this.data.createTime,
        updateTime: this.data.updateTime
      }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
