<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
        v-model="query.lang"
        clearable
        placeholder="语言"
        class="filter-item"
        style="width: 130px"
        @change="toQuery"
    >
      <el-option
          v-for="item in langOptions"
          :key="item.key"
          :label="item.display_name"
          :value="item.key"
      />
    </el-select>
    <el-select
            v-model="query.type"
            clearable
            placeholder="类型"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option
              v-for="item in queryTypeOptions"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
      />
    </el-select>
    <el-input v-model="query.value" clearable placeholder="内容" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','DICT_ALL','DICT_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true">新增</el-button>
      <eForm ref="form" :is-add="true"/>
    </div>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      queryTypeOptions: [
        { key: 'label', display_name: '标签名' },
        { key: 'type', display_name: '类型' },
      ],
      langOptions: [
        { key: 'zh_CN', display_name: '中文' },
        { key: 'zh_Hant', display_name: '繁体' },
        { key: 'en_US', display_name: '英文' },
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('query');
    }
  }
}
</script>
